import React, { useState, useEffect, useRef } from 'react';
import { Snackbar } from '@mui/material';

const PathGame = () => {
    const canvasRef = useRef(null);
    const [grid, setGrid] = useState(Array(10).fill(null).map(() => Array(10).fill(0))); // Initialize grid in state
    const [path, setPath] = useState([]);
    const [hovering, setHovering] = useState(false); // Tracks if user is actively paving
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const gridSize = 10; // 10x10 grid
    const tileSize = 50; // Each tile is 50px by 50px
    const start = { x: 0, y: 0 }; // Starting point
    const end = { x: 9, y: 9 }; // Ending point

    // Timer effect
    useEffect(() => {
        let interval;
        if (startTime && !endTime) {
            interval = setInterval(() => {
                setCurrentTime((Date.now() - startTime) / 1000);
            }, 10);
        }
        return () => clearInterval(interval);
    }, [startTime, endTime]);

    // Initialize the grid and obstacles
    useEffect(() => {
        const initialGrid = [...grid];
        initialGrid[start.y][start.x] = 1; // Mark start
        setGrid(generateObstacles(initialGrid, 15)); // Add obstacles
        setPath([{ x: start.x, y: start.y }]);
        // eslint-disable-next-line
    }, []); // Only run once on mount

    // Generate obstacles
    const generateObstacles = (grid, count) => {
        const newGrid = [...grid];
        let obstacles = 0;

        while (obstacles < count) {
            const x = Math.floor(Math.random() * gridSize);
            const y = Math.floor(Math.random() * gridSize);

            // Ensure obstacle is not on the start or end tiles
            if ((x !== start.x || y !== start.y) && (x !== end.x || y !== end.y) && newGrid[y][x] === 0) {
                newGrid[y][x] = -1; // -1 represents an obstacle
                obstacles++;
            }
        }

        return newGrid;
    };

    // Draw the grid on the canvas
    useEffect(() => {
        if (!canvasRef.current) return; // Guard against null canvas
        
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = gridSize * tileSize;
        canvas.height = gridSize * tileSize;

        // Draw the grid
        for (let y = 0; y < gridSize; y++) {
            for (let x = 0; x < gridSize; x++) {
                if (grid[y] && typeof grid[y][x] !== 'undefined') { // Add null check
                    if (grid[y][x] === -1) {
                        ctx.fillStyle = '#d32f2f'; // Red for obstacles
                    } else if (grid[y][x] === 1) {
                        ctx.fillStyle = '#4caf50'; // Green for path
                    } else {
                        ctx.fillStyle = '#fff'; // White for empty tiles
                    }
                    ctx.fillRect(x * tileSize, y * tileSize, tileSize, tileSize);
                    ctx.strokeStyle = '#ccc';
                    ctx.strokeRect(x * tileSize, y * tileSize, tileSize, tileSize);
                }
            }
        }

        // Draw start and end points
        ctx.fillStyle = '#ff5722'; // Red for start
        ctx.fillRect(start.x * tileSize, start.y * tileSize, tileSize, tileSize);
        ctx.fillStyle = '#3f51b5'; // Blue for end
        ctx.fillRect(end.x * tileSize, end.y * tileSize, tileSize, tileSize);
        // eslint-disable-next-line
    }, [grid]);

    // Handle hover events
    const handleHover = (event) => {
        if (!hovering && !startTime) return; // Ignore hovers if not started
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = Math.floor((event.clientX - rect.left) / tileSize);
        const y = Math.floor((event.clientY - rect.top) / tileSize);

        // Validate coordinates are within bounds
        if (x < 0 || x >= gridSize || y < 0 || y >= gridSize) return;

        const lastTile = path[path.length - 1];
        const isAdjacent =
            (Math.abs(lastTile.x - x) === 1 && lastTile.y === y) ||
            (Math.abs(lastTile.y - y) === 1 && lastTile.x === x);

        if (isAdjacent && grid[y] && grid[y][x] === 0) { // Add null check
            // Mark the tile as part of the path
            const newGrid = [...grid];
            newGrid[y][x] = 1;
            setGrid(newGrid);

            // Add to the path
            setPath([...path, { x, y }]);

            // Check if the end is reached
            if (x === end.x && y === end.y) {
                setHovering(false);
                setEndTime(Date.now());
                setSnackbarMessage(`You finished in ${((Date.now() - startTime) / 1000).toFixed(2)} seconds! 🎉`);
                setSnackbarOpen(true);
                window.location.href = 'https://pavewisepro.com';
            }
        } else if (grid[y][x] === -1) {
            // Reset the game if hovering over a bad box
            setSnackbarMessage('You hit an obstacle! Game resetting...');
            setSnackbarOpen(true);
            resetGame();
        }
    };

    // Start the game on hover over start tile
    const handleStartHover = () => {
        if (!startTime) {
            setStartTime(Date.now());
            setHovering(true);
        }
    };

    // Reset the game
    const resetGame = () => {
        setGrid(Array(10).fill(null).map(() => Array(10).fill(0)));
        setPath([{ x: start.x, y: start.y }]);
        setStartTime(null);
        setEndTime(null);
        setHovering(false);
        setCurrentTime(0);
        const initialGrid = Array(10).fill(null).map(() => Array(10).fill(0));
        initialGrid[start.y][start.x] = 1;
        setGrid(generateObstacles(initialGrid, 15));
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1>Pave the Way</h1>
            <h2>Time: {endTime ? ((endTime - startTime) / 1000).toFixed(2) : currentTime.toFixed(2)} seconds</h2>
            <p>
                Hover over the start (red) to begin. Draw a path by hovering adjacent tiles
                to reach the end (blue). Avoid obstacles!
            </p>
            <canvas
                ref={canvasRef}
                onMouseMove={handleHover}
                onMouseEnter={handleStartHover}
                style={{ border: '1px solid #000', cursor: 'pointer' }}
            />
            <div style={{ marginTop: '20px' }}>
                <button 
                    onClick={resetGame}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        backgroundColor: '#4caf50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Reset Game
                </button>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </div>
    );
};

export default PathGame;